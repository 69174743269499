@use "sass:math";

// body {
//     background-color: blue;
//     color: white;
//     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

//     h1 {
//         text-align: center;

//     }    
// }

// .d-none {
//     display: none;
// }


// $buttonFooter: black;
// $buttonFooterActive: orange;
$timeTransitionButtonFooter: .3s;
$backgroundColorContainer: rgb(92, 92, 228);

// $urlBackgroundImage: 'https://local-busswe.com/assets/image.png';

@mixin item-dividers-backgrounds( $sizeHeight: 140px, $position: top, $backgroundColor: var( --widget-agents-2-background-color-2 ) ) {
    height: $sizeHeight;
    background-image: linear-gradient( to $position, $backgroundColor 50%, transparent );
    // background-image: linear-gradient( to $position, var( --widget-agents-2-footer-button-color-active ) 50%, var( --widget-agents-2-background-color ) );
}

@keyframes icon_agent_border_actve {
    50% { box-shadow: 0px 0px 6px var(--widget-agents-2-text-shadow-title); }
}

@mixin buttonOptionColors( $textColor: white, $backgroundColor: orange, $useHover: false ) {
    color: $textColor;
    fill: $textColor;
    background-color: $backgroundColor;
    border-color: $backgroundColor;

    @if $useHover {
        box-shadow: 0px 0px 15px $backgroundColor;
    }
}

:root {
    // Color para la vista principal

    // Colores para el footer
    --widget-agents-2-footer-button-color: black;
    --widget-agents-2-footer-button-color-active: orange;

    --widget-agents-2-footer-button-background: white;

    // Colores para el body
    --widget-agents-2-background-color-1: rgb(92, 92, 228);
    --widget-agents-2-background-color-2: rgb(230, 111, 32);
    
    --widget-agents-2-text-title: #ffffff;
    --widget-agents-2-text-subtitle: #dcdcdc;
    --widget-agents-2-text-shadow-title: #000000;
    
    --widget-agents-2-option-button-1-color: #ffa500;
    --widget-agents-2-option-button-1-text: #ffffff;
    
    --widget-agents-2-option-button-2-color: #1d1c1c;
    --widget-agents-2-option-button-2-text: #ffffff;
    
    --widget-agents-2-option-button-3-color: #ffffff;
    --widget-agents-2-option-button-3-text: #ffa500;


    // Colores para la vista del chat
    --widget-agents-2-chat-background: rgb(54, 157, 170);
    --widget-agents-2-chat-text: white;

    --widget-agents-2-chat-option-button-1-color: white;
    --widget-agents-2-chat-option-button-1-text: orange;
    
    --widget-agents-2-chat-option-button-2-color: #1d1c1c;
    --widget-agents-2-chat-option-button-2-text: white;
}

#busswe-widget.widget-agent-desing-2 #dialog-widget,
#busswe-widget:not( .widget-agent-desing-2 ) #dialog-widget-2 {
    display: none;
}

// Para el contenedor del widget si se quiere dentro de una sección de la vista, en lugar de por encima de la pagina.
.setToInsideBody {
    position: relative;
    min-height: 80px;
}
.setToInsideBody > #busswe-widget {
    position: absolute;
    z-index: unset;
    bottom: 0;
}

@media only screen and (max-height: 699px) {
    .setToInsideBody:has( details[open] ) {
        // min-height: 500px;
        min-height: 460px;
    }
    .setToInsideBody:has( #busswe-widget.widget-agent-desing-2 details[open] ) {
        min-height: 550px;
    }
}
@media only screen and (min-height: 700px) {
    .setToInsideBody:has( details[open] ) {
        // min-height: 560px;
        min-height: 480px;
    }
    .setToInsideBody:has( #busswe-widget.widget-agent-desing-2 details[open] ) {
        min-height: 665px;
    }
}
// Fin del contenedor.
#busswe-widget.setToRight.widget-agent-desing-2 #dialog-widget-2{
    right: 0;
}

#busswe-widget.widget-agent-desing-2 {

    details {
        position: relative;
        height: 80px;
    }
    details[open] {
        height: 140px;

        summary {
            position: absolute;
            bottom: 0;
        }
    }
    // details summary {
    //     position: absolute;
    //     bottom: 0;
    // }


    #dialog-widget-2 {
        position: absolute;
        bottom: 0;
        display: block;
        margin-bottom: 5.4rem;
        // background-color: $backgroundColorContainer;
        background-color: var(--widget-agents-2-background-color-1);
        color: black;
        border: 1px solid grey;
        border-radius: 25px;
        box-shadow: 1px 2px 12px black;
        font-weight: 400;

        width: 336px;
        height: 430px;

        .container-content-widget {
            position: relative;

            .main-content-widget {
                height: 358px;
                overflow-y: scroll;
                position: relative;
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;

                div:not( .background-media, .divider-backgrounds, .content-main-items, .group-buttons-actions ) {
                    height: 30rem;
                }

                .background-media {
                    // background-image: url( $urlBackgroundImage );
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 300px;
                    border-top-left-radius: 25px;
                    border-top-right-radius: 25px;
                    overflow: hidden;
                    position: relative;

                    video {
                        max-height: inherit;
                        width: 100%;
                        opacity: 0;
                        transition: opacity .5s ease-in;
                    }
                    video.cover-center {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        min-height: 300px;
                        max-height: 600px;
                        max-width: 600px;
                        min-width: 340px;
                        object-fit: cover;
                        object-position: center;
                    }
                    video.show-background-video {
                        opacity: 1; 
                    }

                    .hide-background-footer {
                        // position: relative;
                        // top: -6rem;
                        position: absolute;
                        bottom: -70px;
                        width: 100%;
                        @include item-dividers-backgrounds( 80px, top, var(--widget-agents-2-background-color-1) );
                    }
                }

                .divider-backgrounds {
                    // height: 340px;
                    height: 305px;
                    width: 100%;
                    position: relative;
                    top: -2rem;

                    .to-top {
                        // @include item-dividers-backgrounds( 170px, top);
                        @include item-dividers-backgrounds( 175px, top);
                    }
                    .to-bottom {
                        // @include item-dividers-backgrounds( 170px, bottom);
                        @include item-dividers-backgrounds( 130px, bottom);
                    }
                }

                .content-main-items {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    margin: 1.2rem;
                    margin-top: 14rem;

                    .list-group-agents-icons {
                        list-style: none;
                        margin: unset;
                        padding: unset;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-end;

                        li {
                            img {
                                width: 50px;
                                height: 50px;
                                border-radius: 45px;
                                object-fit: cover;
                                object-position: center;
                                transition: all .3s ease-in;
                            }
                        }
                        
                        li:not( :first-of-type ) {
                            margin-left: .3rem;
                        }

                        li.active-agent {
                            img {
                                --angle: 0deg;

                                width: 65px;
                                height: 65px;
                                border: 3px solid var(--widget-agents-2-text-shadow-title);
                                box-shadow: 0px 0px 20px var(--widget-agents-2-text-shadow-title);
                                animation: icon_agent_border_actve 6s 3s infinite ease;
                                
                                // border-image: linear-gradient( var(--angle), var( --widget-agents-2-footer-button-color-active ), white ), 1;
                                // animation: rotate_border_icon-agent .8s .4s infinite ease-in;
                            }

                            // @keyframes rotate_border_icon-agent {
                            //     to { --angle: 360deg }
                            // }
                        }
                    }

                    .list-group-agents-icons:has( li.active-agent ) li:not( .active-agent ) {
                        opacity: .7;
                    }

                    .title-message, .subtitle-message {
                        display: block;
                        text-shadow: 0px 0px 4px var(--widget-agents-2-text-shadow-title);
                    }

                    .title-message {
                        color: var(--widget-agents-2-text-title);
                        font-size: 1.7rem;
                        font-weight: 600;
                        text-transform: capitalize;
                    }

                    .subtitle-message {
                        color: var(--widget-agents-2-text-subtitle);
                        margin-bottom: .8rem;
                    }

                    .group-buttons-actions {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-evenly;
                        align-items: flex-start;

                        button, a {
                            margin: .5rem;
                            padding: .8rem;
                            line-height: 21px;
                            height: fit-content;
                            border-radius: 40px;
                            background-color: orange;
                            border: 1px solid white;
                            color: white;
                            font-weight: 700;
                            cursor: pointer;
                            text-decoration: none;
                            flex-grow: 1;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            transition: all .3s ease-out;
                            // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                            
                            svg {
                                // fill: white;
                                font-size: 1.4rem;
                                margin-right: .4rem;
                            }
                            span {
                                // font-family: inherit;
                                font-size: .93rem;
                            }
                        }
                    }



                    .group-buttons-actions > *:first-child {
                        margin-left: 0;
                        @include buttonOptionColors( var(--widget-agents-2-option-button-1-text), var(--widget-agents-2-option-button-1-color) );
                    }
                    .group-buttons-actions > *:first-child:hover {
                        @include buttonOptionColors( var(--widget-agents-2-option-button-1-color), var(--widget-agents-2-option-button-1-text), true );
                    }
                    .group-buttons-actions > *:nth-child(2) {
                        margin-right: 0;
                        @include buttonOptionColors( var(--widget-agents-2-option-button-2-text), var(--widget-agents-2-option-button-2-color) );
                    }
                    .group-buttons-actions > *:nth-child(2):hover {
                        @include buttonOptionColors( var(--widget-agents-2-option-button-2-color), var(--widget-agents-2-option-button-2-text), true );
                    }
                    .group-buttons-actions > *:nth-child(3),
                    .group-buttons-actions > *:nth-child(4) {
                        // margin: inherit;
                        // margin-left: 0;
                        // margin-right: 0;
                        flex-grow: 1;
                        @include buttonOptionColors( var(--widget-agents-2-option-button-3-text), var(--widget-agents-2-option-button-3-color) );
                    }
                    .group-buttons-actions > *:nth-child(3):hover,
                    .group-buttons-actions > *:nth-child(4):hover {
                        @include buttonOptionColors( var(--widget-agents-2-option-button-3-color), var(--widget-agents-2-option-button-3-text), true );
                    }
                }
            }
        }
        
        .footer-widget {
            border-top: 1px solid var(--widget-agents-2-footer-button-color);
            background-color: var(--widget-agents-2-footer-button-background);
            height: 4.5rem;
            color: var(--widget-agents-2-footer-button-color);
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
            position: relative;

            ul {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                padding-left: 0;
                margin: 0;
                list-style: none;

                li {
                    width: 150px;
                    text-align: center;
                    cursor: pointer;

                    svg {
                        display: block;
                        margin: 0 auto;
                        font-size: 1.7rem;
                        fill: var(--widget-agents-2-footer-button-color);
                        transition: fill $timeTransitionButtonFooter ease-in-out;
                    }

                    span {
                        display: block;
                        font-size: .8rem;
                        font-weight: 600;
                        text-transform: uppercase;
                        transition: color $timeTransitionButtonFooter ease-in-out;
                    }
                }
                li.active {
                    color: var(--widget-agents-2-footer-button-color-active);

                    svg {
                        fill: var(--widget-agents-2-footer-button-color-active);
                    }
                }
            }

            .footer-container-image-company {
                display: none;
                text-align: center;
                width: 100%;
                position: absolute;
                bottom: 0;

                img {
                    height: 1.2rem;
                    width: 1.2rem;
                    border-radius: 1rem;
                }
            }
        }

        .footer-widget.show-footer-image-company {
            ul {
                height: 80%;
            }
            .footer-container-image-company {
                display: block;
            }
        }


        .container-content-widget.show-chat > :not( .container-main-chat ){
            display: none;
        }


        // Vista del chat
        .container-content-widget .container-main-chat
        {
            width: 100%;
            height: 0px;
            border-radius: 25px;
            background-color: var(--widget-agents-2-background-color-1);
            // background-color: var(--widget-agents-2-chat-background);

            overflow: hidden;
            transition: height .4s ease;
        }

        .container-content-widget.show-chat .container-main-chat
        {
            display: block;
            // width: 100%;
            height: 431px;
            // border-radius: 25px;
            // background-color: var(--widget-agents-2-chat-background);

            // overflow: hidden;
            // transition: height .4s ease;

            .header-main-chat, .body-main-chat {
                padding: 1rem;
            }

            .header-main-chat {
                height: 32.5%;                
                color: var(--widget-agents-2-text-title);
                fill: var(--widget-agents-2-text-title);
                // color: var(--widget-agents-2-chat-text);
                // fill: var(--widget-agents-2-chat-text);

                .group-options-1, .group-options-2 {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }

                .group-options-2 {
                    justify-content: space-around;
                }

                .group-options-1 svg {
                    cursor: pointer;
                }

                .group-options-1 .close-chat-agent {
                    opacity: 0;
                    cursor: unset;
                }

                img {
                    display: block;
                    width: 70px;
                    height: 70px;
                    object-fit: cover;
                    border-radius: 40px;
                    margin: .5rem auto;
                    box-shadow: 0px 0px 5px var(--widget-agents-2-chat-text);
                    animation: image-agent-in-view-chat 5s infinite;
                }

                @keyframes image-agent-in-view-chat {
                    50% { box-shadow: 0px 0px 24px var(--widget-agents-2-chat-text); }
                }


                .group-options-2 button, .group-options-2 a {
                    // width: 42%;
                    width: 2rem;
                    height: fit-content;
                    padding: .4rem;
                    font-size: .9rem;
                    font-weight: 600;
                    border: unset;
                    border-radius: 25px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    text-decoration: none;
                    // transition: width .4s ease;

                    svg {
                        // margin-right: .3rem;
                        margin-right: 0;
                    }
                    span {
                        white-space: nowrap;
                        width: 0;
                        line-height: 21.6px;
                        overflow-x: hidden;
                        transition: width .3s ease;
                    }
                }

                // Una lista con los colores de los botones del header de la vista chat.
                // $listGroupButtonInHeaderChat: 1 var(--widget-agents-2-chat-option-button-1-color) var(--widget-agents-2-chat-option-button-1-text),
                // 2 var(--widget-agents-2-chat-option-button-2-color) var(--widget-agents-2-chat-option-button-2-text);

                $listGroupButtonInHeaderChat: 1 var(--widget-agents-2-option-button-1-color) var(--widget-agents-2-option-button-1-text),
                2 var(--widget-agents-2-option-button-2-color) var(--widget-agents-2-option-button-2-text),
                3 var(--widget-agents-2-option-button-2-color) var(--widget-agents-2-option-button-2-text),
                4 var(--widget-agents-2-option-button-1-color) var(--widget-agents-2-option-button-1-text);

                @each $numberGroupButton, $colorGroupButton, $textGroupButton in $listGroupButtonInHeaderChat {

                    .group-options-2 > *:nth-child( #{$numberGroupButton} ) {
                        transition: all .3s ease;                        
                        @include buttonOptionColors( $textGroupButton, $colorGroupButton );
                    }

                    .group-options-2 > *:nth-child( #{$numberGroupButton} ):hover {
                        @include buttonOptionColors( $colorGroupButton, $textGroupButton, true );

                        width: 42%;
                        
                        svg {
                            margin-right: .3rem;
                        }

                        span {
                            width: auto;
                            overflow-x: unset;
                        }

                    }

                }

            }

            .body-main-chat {
                height: 52.7%;
                background-color: white;
                border-radius: 25px;
                position: relative;

                .container-chat-messages {
                    width: 100%;
                    height: 70%;
                    overflow-y: scroll;
                    display: flex;
                    flex-direction: column;

                    

                    .message-agent, .message-client {
                        margin-bottom: 1rem;
                        font-weight: 500;
                    }
                    .message-agent span, .message-client span {
                        display: inline-block;
                        border-radius: 15px;
                        padding: .6rem;
                    }

                    .message-agent span.message-wait-writting-widget {
                        display: inherit;
                        
                        i {
                            background-color: silver;
                        }
                    }

                    .message-agent {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-end;
                        margin-right: 3rem;

                        picture, img:not( .object-image-message ) {
                            width: 40px;
                            height: 40px;
                            margin-right: .5rem;
                        }
                        img:not( .object-image-message ) {
                            border-radius: 40px;
                            object-fit: cover;
                            object-position: center;
                        }
                        span {
                            border-bottom-left-radius: 0px;
                            background-color: rgb(223, 221, 221);
                            color: black;
                            white-space: break-spaces;
                        }
                        > span > img.object-image-message {
                            min-height: 50px;
                            min-width: 50px;
                            max-width: 100%;
                            border-radius: 1rem;
                        }
                    }
                    .message-agent.hyperlinks-buttons-message {
                        display: block;
                        // display: flex;

                        > .container-all-group-buttons {
                            flex-direction: row;
                            align-items: flex-start;
                            flex-wrap: wrap;
                            position: relative;
                            // display: block;
                            display: flex;
                            height: 108px;
                            overflow-y: hidden;

                            a, a span {
                                width: 96%;
                            }
                            a {
                                margin-bottom: .4rem;
                            }
                            a span {
                                text-align: center;

                                svg {
                                    margin-left: .6rem;
                                }
                            }

                            a.phone-button-message-hyperlink {
                                width: 50%;

                                span {
                                    padding-right: 0;
                                    padding-left: 0;
                                }
                            }

                            > .show-info-target-button-container {
                                display: flex;
                                flex-wrap: wrap;
                                width: 95%;
                                background-color: rgb(236, 233, 233);
                                padding-left: 0.4rem;
                                padding-right: 0.4rem;
                                padding-top: 0.2rem;
                                margin-bottom: 0.4rem;
                                border-radius: .5rem;

                                > span:first-child {
                                    width: 100%;
                                    margin-bottom: 0.2rem;
                                    padding: unset;
                                    font-size: 0.8rem;
                                    text-align: center;
                                    background-color: unset;

                                    text-wrap: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                > a {
                                    flex: 1;
                                    span {
                                        border: 1px solid;
                                        border-bottom-left-radius: 15px;
                                    }
                                }
                            }

                            .show-info-target-button-container:has( > a:nth-of-type( 1 ):last-child ) > a span {
                                width: -moz-available;
                                width: -webkit-fill-available;;
                            }
                        }

                        > .container-all-group-buttons.show-group-buttons {
                            height: auto;
                            overflow-y: unset;
                        }

                        > .container-all-group-buttons.show-group-buttons:has( > a:nth-of-type( 1 ):last-child ) > a {
                            width: 100%;
                        }

                        > .container-all-group-buttons.show-group-buttons ~ button.btn-toggle-show-group-buttons {
                            > svg {
                                transform: rotate( 0deg );
                            }
                        }

                        button.btn-toggle-show-group-buttons {
                            // position: absolute;
                            // bottom: 0;
                            // left: 50%;
                            // right: 50%;
                            display: block;
                            margin-right: auto;
                            margin-left: auto;
                            width: 15px;
                            height: 24px;
                            background-color: silver;
                            fill: black;
                            border: unset;
                            border-radius: 40px;
                            padding: 0.4rem 0.8rem;
                            padding-top: .2rem;
                            opacity: .85;
                            transition: opacity 0.3s ease;

                            > svg {
                                transition: all ease-in .3s;
                                transform: rotate( 180deg );
                            }
                        }
                        button.btn-toggle-show-group-buttons:hover {
                            opacity: 1;
                        }
                    }

                    .message-client {
                        text-align: end;
                        margin-left: 3rem;
                        span {
                            border-bottom-right-radius: 0px;
                            background-color: rgb(5, 5, 138);
                            color: white;
                            text-align: end;
                            
                            a {
                                text-decoration: none;
                                color: inherit;
                            }

                            svg {
                                fill: white;
                                font-size: 1.5rem;
                            }
                        }
                    }

                }

                .container-chat-messages > div:first-of-type {
                    margin-top: 1rem;
                }

                .hide-messages {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: 1rem;
                    height: 2rem;
                    background-image: linear-gradient( to bottom, white, transparent );
                }

                // Textarea e button para enviar el mensaje
                .container-options-input {
                    position: relative;
                    width: 100%;
                    height: 30%;

                    textarea {
                        // width: 89%;
                        width: 80%;
                        height: 70%;
                        padding: .6rem;
                        // padding-right: 1.5rem;
                        padding-right: 3.1rem;
                        border: 1px solid silver;
                        border-radius: 10px;
                        font-weight: 500;
                        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                        resize: none;
                    }

                    section.options-button-to-send-message {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        width: 2.5rem;
                        margin-top: .4rem;
                        margin-bottom: .8rem;
                        margin-right: .1rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-end;

                        button {
                            // position: absolute;
                            // bottom: 0;
                            // right: 0;
                            border: unset;
                            border-radius: 40px;
                            padding: .4rem .8rem;
                            background-color: silver;
                            fill: black;
                            line-height: 24px;
                            opacity: .7;
                            cursor: pointer;
                            transition: opacity .3s ease;
                        }
                        button:first-child {
                            font-size: 1rem;
                        }
                        button:hover {
                            opacity: 1;
                        }
                    }

                    article.container-options-record-audio {
                        display: none;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        padding: 1rem;
                        border-radius: 10px;
                        background-color: rgb(233, 227, 227);
                        opacity: .95;

                        > section {
                            display: none;
                            width: 100%;
                            height: 100%;
                            // background-color: blue;
                            // color: white;

                            flex-direction: row;
                            justify-content: space-around;
                            align-items: center;
                        }

                        section.group-option-record-in-process {
                            div > span {
                                display: block;
                                margin-bottom: .2rem;
                            }
                        }

                        section.group-option-record-in-process,
                        section.group-option-record-finished {

                            button {
                                width: 3rem;
                                height: 3.2rem;
                                padding: 1px 4px;
                                font-size: 1.8rem;
                                border-radius: 50px;
                                cursor: pointer;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                @include buttonOptionColors( var(--widget-agents-2-option-button-1-text), var(--widget-agents-2-option-button-1-color) );
                            }

                        }

                        section.group-option-record-finished {
                            flex-wrap: wrap;

                            span:first-child {
                                width: 100%;
                                text-align: center;
                            }

                            > button {
                                width: 2.2rem;
                                height: 2.4rem;
                                padding: 1px 4px;
                                font-size: 1.6rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }

                    article.container-options-record-audio.show-option-process:not( .show-option-finished ),
                    article.container-options-record-audio.show-option-finished:not( .show-option-process ) {
                        display: block;
                    }

                    article.container-options-record-audio.show-option-process > section.group-option-record-in-process,
                    article.container-options-record-audio.show-option-finished > section.group-option-record-finished {
                        display: flex;
                        
                    }
                }
            }
        }

        .container-content-widget:not( .show-chat ) > .container-main-chat
        {
            height: 0;
            opacity: 0;
        }        


        /* Scrollbar */
        /* Hide scrollbar for Chrome, Safari and Opera */
        .container-chat-messages::-webkit-scrollbar, .main-content-widget::-webkit-scrollbar, .hide-scrollbar-item::-webkit-scroll {
            display: none;
        }
        
        /* Hide scrollbar for IE, Edge and Firefox */
        .container-chat-messages, .main-content-widget, .hide-scrollbar-item {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        } 
    }

    // Ocultar las opciones para mostrar la vista del Chat
    #dialog-widget-2.hide-options-to-contact-agent .show-option-to-contact-agent-button,
    #dialog-widget-2 .option-to-show-widget-schedule-appointment:not( .show-option-widget-external ),
    #dialog-widget-2 .option-to-show-widget-call-agent:not( .show-option-widget-external ) {
        display: none !important;
    }

    // Responsive
    // Si el height de la pantilla supera los 700px
    @media only screen and (min-height: 700px) {
        #dialog-widget-2 {
            margin-bottom: 6.4rem;
            height: 545px;

            .container-content-widget {
                .main-content-widget {
                    height: 473px;
                }
            }

            .container-content-widget.show-chat .container-main-chat {
                height: 546px;

                .header-main-chat {
                    height: 25%;
                }
                .body-main-chat {
                    height: 63.7%;

                    .container-options-input button {
                        bottom: .7rem;
                    }
                }
            }
        }

    } 

}