@use 'desing2';
@use "modal_image";

/* Estilos para el body para pruebas */
body {
    margin: 0;
}

:root {
    --colorBackgroundButtonWidgetAgents: rgb(99, 191, 161);
}

/* Estilos para el widget */
/* Posicion boton del widget */
#busswe-widget {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    z-index: 1001;
}
#busswe-widget:has( aside details[open] ) {
    z-index: 1001;
}

@media (max-width: 493px)
{
  #busswe-widget {
    right: 1rem;
    min-width: 245px;
  }

  #busswe-widget.setToRight {
    right: 1rem;
    left: 1rem;
  }

  #dialog-widget-area {
    height: 24.07rem;
  }
}

@media (min-width:494px) and (max-width:599px) {
    #busswe-widget {
     right:unset;
     width:60%
    }
}

@media (min-width: 600px) and (max-width: 900px)
{
  #busswe-widget {
    right:unset;
    // width: 45%;
    // width: 280px;
    min-width: 245px;
    max-width: 320px;
  }

  #dialog-widget-area {
    height: 59.5vh;
  }
}

@media (min-width: 901px)
{
  #busswe-widget {
    right: unset;
    min-width:20rem;
    width: 24.87vw;
  }

  #dialog-widget-area {
    height: 26.7rem;
  }
}

@media (min-width: 494px) {
    #busswe-widget.setToRight {
        right: 1rem;
        left: unset;
    }
}

#busswe-widget aside {
    all: unset;
    font-family: inherit;
}

/* Ajustes para evitar que los styles de las paginas modifiquen el widget */
#busswe-widget, #busswe-widget #dialog-widget, #busswe-widget #dialog-widget-agents section {
    overflow-x: initial;
}

#busswe-widget, #busswe-widget * {
    box-sizing: initial;
}
/* Fin */

#busswe-widget.setToRight #botton-widget {
    float: right;
    right: 0;
}

section#busswe-widget.widget-agent-init {
    display: none;
    opacity: 0;
}

#busswe-widget #botton-widget, #busswe-widget #dialog-widget {
    box-shadow: .3rem .3rem .8rem rgb(0, 0, 0, 0.3);
}

/* Scrollbar */
/* Hide scrollbar for Chrome, Safari and Opera */
#busswe-widget #dialog-widget::-webkit-scrollbar, #busswe-widget #dialog-widget #dialog-widget-area-actions-textarea::-webkit-scrollbar,
#busswe-widget #dialog-widget-area-history::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
#busswe-widget #dialog-widget, #busswe-widget #dialog-widget #dialog-widget-area-actions-textarea, #busswe-widget #dialog-widget-area-history {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
} 

/* Ocultando la fecha de la etiquetas details summary */
#busswe-widget aside details summary::-webkit-details-marker {
    display:none;
}

#busswe-widget aside details > summary:first-of-type {
    list-style-type: none;
}

#busswe-widget aside details summary#botton-widget span {
    display: none;
}

#busswe-widget aside details[open] summary#botton-widget span {
    display: block;
    padding-left: 1rem;
}

#busswe-widget aside details[open] summary#botton-widget {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Diseño para el boton */
#botton-widget {
    padding: 1rem;
    border: 1px solid aliceblue;
    border-radius: 5rem;
    color: white;
    background-color: darkcyan;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
}

#botton-widget svg {
    height: 2rem;
}

/* Posición y diseño para el dialog del widget */
#dialog-widget {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 6rem;
    height: auto;
    background-color: steelblue;
    color: black;
    border: 1px solid aliceblue;
    border-radius: 1rem;
    overflow: hidden;
}


details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
}
  
@keyframes sweep {
    0%    {opacity: 0; transform: translateX(-10px)}
    100%  {opacity: 1; transform: translateX(0)}
}

/* Button para regresar a la lista de agentes */
#dialog-widget-area-button-back {
    margin-block-end: .2rem;
    margin: .8rem .8rem .6rem .8rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#dialog-widget-area-button-back svg {
    width: 3rem;
    height: 1.5rem;
    cursor: pointer;
}

#dialog-widget-area-button-back img {
    height: 1.2rem;
    max-width: 3rem;
}

#dialog-widget-area-button-back span {
    margin-left: .5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#dialog-widget-agents-list, #dialog-widget-agents-image {
    background-color: antiquewhite;
}
#dialog-widget.widgetWithoutBanner #dialog-widget-agents-image {
    height: 0;
}
#dialog-widget.widgetWithoutBanner #dialog-widget-agents-image img {
    display: none;
}

@media (max-height: 600px) {
    #dialog-widget {
        max-height: 26rem;
    }

    #dialog-widget.widgetWithoutBanner #dialog-widget-agents-image.show-list-agent {
        height: 17rem;
    }
    
    #dialog-widget-agents-list.show-list-agent {
        max-height: 21rem;
    }
}
@media (min-height: 601px) {
    #dialog-widget {
        max-height: 31rem;
    }

    #dialog-widget.widgetWithoutBanner #dialog-widget-agents-image.show-list-agent {
        height: 20rem;
    }

    #dialog-widget-agents-list.show-list-agent {
        max-height: 24rem;
    }
}
  
@media (max-height: 699px) {
    #dialog-widget div#dialog-widget-agents, #botton-widget, #dialog-widget-area-history {
        font-size: 1.1rem;
    }

    #dialog-widget-area-actions-textarea {
        font-size: 1rem;
    }
}
@media (min-height: 700px) and (max-height: 799px) {
    #dialog-widget div#dialog-widget-agents, #botton-widget, #dialog-widget-area-history {
        font-size: 1.2rem;
    }

    #dialog-widget-area-actions-textarea {
        font-size: 1.1rem;
    }
}
@media (min-height: 800px) {
    #dialog-widget div#dialog-widget-agents, #botton-widget, #dialog-widget-area-history {
        font-size: calc(1.3rem + .1vh + .1vw);
    }

    #dialog-widget-area-actions-textarea {
        font-size: 1.2rem;
    }
}

/* Area de texto y acciones */
#dialog-widget-area {
    width: 100%;
    height: auto;
    display: block;
}

/* Reacuadro historial del chat */
#dialog-widget-area-history {
    height: 19.2rem;
    background-color: ghostwhite;
    border-radius: 1rem 1rem 0 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
}

#dialog-widget-area-history-content {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    -webkit-justify-content: flex-end;
    align-items: flex-end;
    -webkit-align-items: flex-end;
}

/* Estilos para los mensajes del historial */
#dialog-widget-area-history #dialog-widget-area-history-content div.widget-area-history-item, 
#dialog-widget-area-history #dialog-widget-area-history-content div.widget-area-history-item {
    white-space: pre-line;
    width: auto;
    height: auto;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    border-radius: 1rem;
}

#dialog-widget-area-history div#dialog-widget-area-history-content div.widget-area-history-item {
    margin-bottom: 1.5rem;
}

#dialog-widget-area-history #dialog-widget-area-history-content div.widget-area-history-item {
    text-align: start;
    display: flex;
    max-width: 80%;
}

#dialog-widget-area-history #dialog-widget-area-history-content div.widget-area-history-item a{
    color: inherit;
}

#dialog-widget-area-history div.item-system {
    align-self: start;
}

#dialog-widget-area-history div.item-system {
    background-color: darkcyan;
    color: white;
}

#dialog-widget-area-history div.item-client {
    align-self: end;
}

#dialog-widget-area-history div.item-client {
    background-color: mediumseagreen;
    color: white;
}

/* Area de la acción, area del texto y boton */
#dialog-widget-area-actions {
    display: grid;
    grid-template-columns: 86% 10%;
    max-height: 5.3rem;
    padding: 0 .8rem .8rem;
    border-radius: 0 0 .8rem .8rem;
    border-top: 1px solid #dbdbdb;
    padding-top: .5rem;
    background-color: whitesmoke;
}

#dialog-widget-area-actions textarea {
    border: 0;
    border-bottom: 1px solid #dbdbdb;
    border-radius: 1rem;
    margin-right: 3%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    max-height: 100%;
    resize: none;
    background-color: whitesmoke;
}

#dialog-widget-area-actions button {
    margin-left: 0;
    padding: unset;
    border: 0;
    background-color: whitesmoke;
    color: black;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#dialog-widget-area-actions button svg {
    width: 2rem;
    height: 2rem;
}


/* Mostrando la lista de agentes */
#dialog-widget.show-list-agent {
    overflow-y: auto;
    padding: unset;
    padding-top: 1rem;
}

#dialog-widget.show-list-agent div#dialog-widget-agents {
    display: block;
}

#dialog-widget.show-list-agent div#dialog-widget-area {
    display: none;
}

#dialog-widget:not(.show-list-agent) div#dialog-widget-agents {
    display: none;
}

#dialog-widget-agents-header {
    height: 4rem;
    text-align: center;
    padding-bottom: 1rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;

    border-bottom: 1px solid white;
    display: flex;
    align-items: flex-start;
}

#dialog-widget-agents-header img {
    display: block;
    max-height: 4rem;
    border-radius: .8rem;
    box-shadow: 0 4px 3px rgba(0,0,0,0.3);
}

#dialog-widget-agents-header span {
	margin-left: .5rem;
	word-break: break-all;
    align-self: center;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#dialog-widget-agents-button-show-list {
    text-align: center;
    background-color: antiquewhite;
    padding-top: .2rem;
    padding-bottom: .22rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

#dialog-widget-agents-button-show-list button {
    border: 0;
    background-color: antiquewhite;
    cursor: pointer;
    margin-right: .5rem;
}

#dialog-widget-agents-button-show-list svg {
    height: 2.4rem;
    color: grey;
    transition: .3s ease;
    padding-left: 1px;
}

#dialog-widget-agents-button-show-list.show-list-agent button svg {
    transform: rotate(180deg);
}

#dialog-widget-agents-button-show-list label {
    cursor: pointer;
}

#dialog-widget-agents-image {
	width: 100%;
	padding-bottom: 6px;
    transition: height 1s ease;
}

#dialog-widget-agents-image img {
	height: 100%;
	object-fit: cover;
	object-position: center;
	border-radius: 0 0 1rem 1rem;
	box-shadow: 0 4px 3px rgba(0,0,0,0.3);
	max-width: 100%;
	display: block;
    margin-right: auto;
    margin-left: auto;
}

#dialog-widget-agents-list {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: antiquewhite;
    position: absolute;
    left: 0;
    right: 0;
    top: -100rem;
    z-index: 3;
    height: auto;
    max-height: 0;

    border-radius: 0 0 .8rem .8rem;
    border-bottom: 1px solid black;
    box-shadow: 0 4px 3px rgba(0,0,0,0.3);
    overflow-y: hidden;
    transition: .4s ease;

    text-align: left;
}

#dialog-widget-agents-list.show-list-agent {
    padding-top: 1rem;
    padding-bottom: 1rem;
    top: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

#dialog-widget-agents-list.show-list-agent article {
    opacity: 1;
    overflow: unset;
}

#dialog-widget-agents-list.show-list-agent article.hightlight-agent {
    position: relative;
    -webkit-box-shadow: 0px 0px 18px 8px var( --colorBackgroundButtonWidgetAgents );
    -moz-box-shadow: 0px 0px 18px 8px var( --colorBackgroundButtonWidgetAgents );
    box-shadow: 0px 0px 18px 8px var( --colorBackgroundButtonWidgetAgents );
    border-radius: 1rem;
    padding: .3rem;
    animation-name: hightlight_agent_animate;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
}

@keyframes hightlight_agent_animate {
    0% { box-shadow: 0px 0px 18px 8px var( --colorBackgroundButtonWidgetAgents ); }
    50% { border-bottom-left-radius: 0rem; border-bottom-right-radius: 0rem; box-shadow: 0px 0px 0px 0px var( --colorBackgroundButtonWidgetAgents ); }
    100% { box-shadow: 0px 0px 18px 8px var( --colorBackgroundButtonWidgetAgents ); }
}

#dialog-widget-agents-list.show-list-agent article div {
    display: none;
}
#dialog-widget-agents-list.show-list-agent article.hightlight-agent div {
    display: block;
    position: absolute;
    bottom: .5rem;
    right: 2rem;
    animation-name: hightlight_agent_hand_container_animate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

#dialog-widget-agents-list.show-list-agent article.hightlight-agent div svg,
#dialog-widget-agents-button-show-list.hightlight_button_to_list_agents > div svg {
    width: 40px;
    height: 40px;
    transform: rotate(300deg);
    animation-name: hightlight_agent_hand_animate;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes hightlight_agent_hand_container_animate {
    0% { right: 2rem; }
    45% { right: 2.5rem; bottom: .7rem; }
    50% { right: 2.25rem; bottom: .6rem; }
    55% { right: 2.5rem; bottom: .7rem; }
    100% { right: 2rem }
}
@keyframes hightlight_agent_hand_animate {
    0% {  }
    45% { filter: drop-shadow(0 0 0.35rem var( --colorBackgroundButtonWidgetAgents ) ); }
    55% { filter: drop-shadow(0 0 0.35rem var( --colorBackgroundButtonWidgetAgents ) ); }
    100% {  }
}

#dialog-widget-agents-button-show-list.hightlight_button_to_list_agents {
    position: relative;
}
#dialog-widget-agents-button-show-list > div {
    display: none;
}
#dialog-widget-agents-button-show-list.hightlight_button_to_list_agents > div {
    display: block;
    position: absolute;
    right: 0;
    left: 0rem;
    bottom: 2rem;
    animation-name: hightlight_agent_hand_button_list_animate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}
#dialog-widget-agents-button-show-list.hightlight_button_to_list_agents > div svg {
    transform: rotate(180deg);
    color: white;
    filter: drop-shadow(0 0 0.35rem black) !important;
}
@keyframes hightlight_agent_hand_button_list_animate {
    0% { right: 2rem; }
    45% { bottom: 2.5rem; }
    50% { bottom: 2.2rem; }
    55% { bottom: 2.5rem; }
    100% { right: 2rem }
}

#dialog-widget-agents-list article {
    width: 100%;
    opacity: 0;
    /* min-height: 5rem; */
    margin-bottom: 1.5rem;
    border-bottom: 1px solid grey;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    transition: .3s ease;
    overflow: hidden;
    height: 0;
    padding-bottom: .5rem;
}

@media (min-width: 1200px) {
    #dialog-widget-agents-list article {
        min-height: 8rem;
    }
}

@media (max-width: 1199px) {
    #dialog-widget-agents-list article {
        min-height: 6rem;
    }
}

#dialog-widget-agents-list article:last-child {
    margin-bottom: unset;
    border: unset;
}

#dialog-widget-agents-list article picture {
    width: 30%;
    height: 100%;
    text-align: center;
    display: block;
}

#dialog-widget-agents-list article picture img {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    object-fit: cover;
}

#dialog-widget-agents-list article section {
    width: 65%;
    padding-left: .5rem;
}

#dialog-widget-agents-list article section span {
    margin-bottom: .5rem;
    display: block;
    text-transform: capitalize;
}

#dialog-widget-agents-list article section span.item-agent-status i {
    border-radius: 4rem;
    height: 1rem;
    width: 1rem;
    background-color: green;
    margin-right: .5rem;
}

#dialog-widget-agents-list article section span.item-agent-status {
    color: green;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#dialog-widget-agents-list .item-agent-profession, #dialog-widget-agents-list .item-agent-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* Removiendo el focus del text area */
#dialog-widget-area-actions textarea:focus {
    outline: none;
}

/* Animación de tres puntos */
.message-wait-writting-widget {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: .4rem;
}
.message-wait-writting-widget i {
    display: block;
    width: .5rem;
    height: .5rem;
    background-color: white;
    border-radius: 1rem;
    margin-right: .3rem;
    position: relative;
    animation-name: chat-system-wait-writting;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
}
@keyframes chat-system-wait-writting {
    0% { transform: translateY(0rem) }
    50% { transform: translateY(-.4rem) }
    100% { transform: translateY(0rem) }
}
  
.message-wait-writting-widget i.wait-1 {
    animation-delay: 0s;
}
.message-wait-writting-widget i.wait-2 {
    animation-delay: .4s;
}
.message-wait-writting-widget i.wait-3 {
    animation-delay: .8s;
}